import React from "react";
import LivePairsTable from "../../components/LivePairsTable/LivePairsTable";
import Header from "../../components/Header/Header";

import BlockchainFilter from "../../components/BlockchainFilter/BlockchainFilter";
import PairsFilter from "../../components/PairsFilter/PairsFilter";

import "./Desktop.css";


function Desktop ({ setModalIsOpen }) {
  return (
    <div className="desktop">
          <div className="right-screen">
            <div className="right-screen_container">
                <Header type={'desktop'} />
                <BlockchainFilter />
                <LivePairsTable />
            </div>
          </div>
          <PairsFilter setModalIsOpen={setModalIsOpen} />
    </div>
  );
};

export default Desktop;