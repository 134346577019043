import React, { useEffect } from 'react';

import './LivePairsWishlistTable.css';
import LivePairsWishlistTableField from '../LivePairsWishlistTableField/LivePairsWishlistTableField';


const LivePairsWishlistTable = () => {

    // const [data, setData] = useState([]);


    useEffect(() => {
        const syncScroll = () => {
            const tbody = document.querySelector('.scrollable-table tbody');
            const thead = document.querySelector('.scrollable-table thead');
            thead.style.transform = `translateY(${tbody.scrollTop}px)`;
        };

        const tbody = document.querySelector('.scrollable-table tbody');
        tbody.addEventListener('scroll', syncScroll);

        return () => {
            tbody.removeEventListener('scroll', syncScroll);
        };
    }, []);

    return (
        <>
            <div className="headers">
                <div className="frame-5">
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Rank by:</div>
                    </div>
                    <div className="frame-6">
                        <div className="text-wrapper-5">Newest</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">Gainers</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">Most volume</div>
                    </div>
                </div>
                <div className="frame-5">
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Liquidity:</div>
                    </div>
                    <div className="frame-6">
                        <div className="text-wrapper-5">Any</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$1K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$5K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$10K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$50K</div>
                    </div>
                </div>
            </div>
            <div className="table">
                <div className="scrollable-table-container">
                    <table className="scrollable-table">
                        <thead>
                            <tr>
                                <th className='th-left'>Pair name</th>
                                <th className='th-right'>DEX</th>
                                {/* <th className='th-right'>Score</th> */}
                                <th className='th-right'>Price</th>
                                <th className='th-right'>Age</th>
                                <th className='th-right'>Liquidity</th>
                                <th className='th-right'>FDV</th>
                                <th className='th-right'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {data
                                .slice(0, 10)
                                .map((item, index) => (
                                    <LivePairsTableField key={index} data={item} />
                                ))} */}
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <LivePairsWishlistTableField key={index} data={index} />
                                ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
};

export default LivePairsWishlistTable;
