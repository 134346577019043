import React, { useState } from 'react';
import { Routes , Route, Navigate } from "react-router-dom";

import Sidebar from './components/Sidebar/Sidebar';
import Desktop from './pages/Desktop/Desktop';

import NewPairsToken from './pages/NewPairsToken/NewPairsToken';
import Wishlist from './pages/Wishlist/Wishlist';
import TokenDetails from './pages/TokenDetails/TokenDetails';
import Alerts from './pages/Alerts/Alerts';
import FilterModal from './components/ModalAlert/ModalAlert';

import './App.css';



function App() {

  const [modalIsOpen, setModalIsOpen] = useState(false);


  return (
    <div className="App">
      <Sidebar />
      <Routes>
      <Route path="/pairs" element={<Desktop setModalIsOpen={setModalIsOpen}  />} />
      {/* <Route path="/eth/pairs" element={<Desktop setChain={setChain} />} /> */}

      <Route path="/token-details" element={<TokenDetails />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/alerts" element={<Alerts />} />
      <Route path="/new-tokens" element={<NewPairsToken />} />


      <Route path="*" element={<Navigate to="/pairs" />} />
    </Routes>

    <FilterModal isOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />

    </div>
  );
}

export default App;
