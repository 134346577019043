import React from 'react';
import { FilterSVG } from "../../images/SVGElements/Filter";
import './PairsFilter.css'

const PairsFilter = ({setModalIsOpen}) => {


  return (
    <div className="filter-container" onClick={() => setModalIsOpen(true)}>
    <FilterSVG />
    <p>Rank & filter</p>
  </div>
  );
};

export default PairsFilter;