import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 90%;
  max-width: 440px;
  height: 295px;
  border-radius: 8px;
  background: #262332;
  color: white;
  position: relative;
`;

const ModalTitle = styled.h2`
  font-size: 14px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const ModalTop = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const ModalBody = styled.div`
  padding: 0px;
`;

const RankByDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
`;

const CustomSelect = styled.select`
  background: transparent;
  border: 1px solid rgba(204, 190, 255, 0.14);
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  width: 144px;
`;

const InputWithRec = styled.div`
  position: relative;
`;

const CustomInput = styled.input`
  background: transparent;
  width: 144px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(204, 190, 255, 0.14);
  color: #FFF;
`;



const Rectangle = styled.div`
  background: rgba(204, 190, 255, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:0;
  right: 0;
  min-width: 28px;
  height: 18px;
  padding: 8px;
`;

const RankButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
`;

const CustomButtonOne = styled.button`
  border-radius: 8px;
  background: rgba(204, 190, 255, 0.14);
  padding: 8px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  border: 0;
  cursor: pointer;
`;


const CustomButtonTwo = styled.button`
  border-radius: 8px;
  background: #7D6CBB;
  padding: 8px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  border: 0;
  cursor: pointer;
`;


const FilterModal = ({ isOpen, setModalIsOpen }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTop>
          <CloseButton onClick={() => setModalIsOpen(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 26" fill="none">
              <path d="M17.913 5.44866L12 11.8544L6.087 5.44866C5.94478 5.30768 5.75784 5.23157 5.56511 5.2362C5.37238 5.24082 5.18874 5.32583 5.05242 5.4735C4.91611 5.62117 4.83765 5.82012 4.83338 6.02891C4.82911 6.2377 4.89936 6.44022 5.0295 6.59429L10.9395 13L5.028 19.4042C4.95578 19.4787 4.89805 19.5682 4.85819 19.6673C4.81834 19.7663 4.79718 19.8731 4.79594 19.9811C4.79471 20.0892 4.81342 20.1964 4.851 20.2966C4.88857 20.3967 4.94424 20.4877 5.01474 20.5641C5.08523 20.6406 5.16913 20.7011 5.2615 20.7419C5.35386 20.7827 5.45283 20.8032 5.55259 20.802C5.65235 20.8008 5.75089 20.778 5.8424 20.735C5.93392 20.692 6.01657 20.6295 6.0855 20.5514L12 14.1473L17.913 20.553C18.0552 20.694 18.2422 20.7701 18.4349 20.7655C18.6276 20.7609 18.8113 20.6759 18.9476 20.5282C19.0839 20.3805 19.1624 20.1816 19.1666 19.9728C19.1709 19.764 19.1006 19.5615 18.9705 19.4074L13.0575 13.0017L18.9705 6.59429C19.0427 6.51973 19.1005 6.43028 19.1403 6.33119C19.1802 6.23211 19.2013 6.1254 19.2026 6.01733C19.2038 5.90926 19.1851 5.80201 19.1475 5.70189C19.1099 5.60177 19.0543 5.51079 18.9838 5.43432C18.9133 5.35784 18.8294 5.2974 18.737 5.25655C18.6446 5.21571 18.5457 5.19527 18.4459 5.19646C18.3461 5.19765 18.2476 5.22042 18.1561 5.26346C18.0646 5.30649 17.9819 5.36891 17.913 5.44704V5.44866Z" fill="#84838C"/>
          </svg>
          </CloseButton>
          <ModalTitle>Rank & filter</ModalTitle>
        </ModalTop>
        <ModalBody>
          <RankByDiv>
            <p>Rank by:</p>
            <CustomSelect>
              <option>Default</option>
              <option>Default</option>
            </CustomSelect>
            <CustomSelect>
              <option>Descending</option>
              <option>Descending</option>
            </CustomSelect>
          </RankByDiv>

          <RankByDiv>
            <p>Liquidity:</p>
            <InputWithRec>
              <CustomInput placeholder='Min'></CustomInput>
              <Rectangle>$</Rectangle>
            </InputWithRec>
            <InputWithRec>
              <CustomInput placeholder='Max'></CustomInput>
              <Rectangle>$</Rectangle>
            </InputWithRec>
          </RankByDiv>

          <RankByDiv>
            <p>Pair age:</p>
            <InputWithRec>
              <CustomInput placeholder='Min'></CustomInput>
              <Rectangle>hours</Rectangle>
            </InputWithRec>
            <InputWithRec>
              <CustomInput placeholder='Max'></CustomInput>
              <Rectangle>hours</Rectangle>
            </InputWithRec>
          </RankByDiv>

          <RankButtons>
            <CustomButtonOne>Clear all</CustomButtonOne>
            <CustomButtonTwo>Apply</CustomButtonTwo>
          </RankButtons>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default FilterModal;
