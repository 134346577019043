import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LivePairsTableField from '../LivePairsTableField/LivePairsTableField';

// import { ArrowUpSVG } from '../../images/SVGElements/ArrowUp';
// import { ArrowDownSVG } from '../../images/SVGElements/ArrowDown';

import './LivePairsTable.css';


const LivePairsTable = () => {

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const syncScroll = () => {
            const tbody = document.querySelector('.scrollable-table tbody');
            const thead = document.querySelector('.scrollable-table thead');
            thead.style.transform = `translateY(${tbody.scrollTop}px)`;
        };

        const tbody = document.querySelector('.scrollable-table tbody');
        tbody.addEventListener('scroll', syncScroll);

        return () => {
            tbody.removeEventListener('scroll', syncScroll);
        };
    }, []);

    const fetchData = (page) => {
        setLoading(true);
        axios.get(`http://localhost:8888/api/pools-info?page=${page}`)
            .then(({ data }) => {
                setData(data.pools);
                setTotalCount(data.totalCount);
            })
            .catch(error => {
                console.error('Помилка запиту:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePageChange = (increment) => {
        const nextPage = currentPage + increment;

        if (nextPage > 0 && nextPage <= Math.ceil(totalCount / 30)) {
            setCurrentPage(nextPage);
        }
    };

    return (
        <>
            <div className="headers">
                <div className="frame-5">
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Rank by:</div>
                    </div>
                    <div className="frame-6">
                        <div className="text-wrapper-5">Newest</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">Gainers</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">Most volume</div>
                    </div>
                </div>
                <div className="frame-5">
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Liquidity:</div>
                    </div>
                    <div className="frame-6">
                        <div className="text-wrapper-5">Any</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$1K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$5K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$10K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$50K</div>
                    </div>
                </div>
            </div>
            <div className='table'>
                <div className='scrollable-table-container'>
                    {loading ? (
                        <p className='loader'>Loading...</p>
                    ) : (
                        <table className='scrollable-table'>
                            <thead>
                                <tr>
                                    <th className='th-left'>Pair name</th>
                                    <th className='th-right'>DEX</th>
                                    {/* <th className='th-right'>Score</th> */}
                                    <th className='th-right'>Price</th>
                                    <th className='th-right'>Age</th>
                                    <th className='th-right'>Liquidity</th>
                                    <th className='th-right'>FDV</th>
                                    <th className='th-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <LivePairsTableField key={index} data={item} />
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            {!loading &&
                <div className='pages-section'>
                    <p>Showing pairs {(currentPage - 1) * 30 + 1}-{Math.min(currentPage * 30, totalCount)} of {totalCount}</p>
                    {currentPage !== 1 && <button onClick={() => handlePageChange(-1)}>Previous</button>}
                    {currentPage !== Math.ceil(totalCount / 30) && <button onClick={() => handlePageChange(1)}>Next</button>}
                </div>
            }

        </>

    );
};

export default LivePairsTable;
