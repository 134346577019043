import React from "react";

import "./Alerts.css";
import Header from "../../components/Header/Header";


function Alerts() {
  return (
    <div className="desktop">
          <div className="right-screen">
            <div className="alerts-container">
              <Header type={'alerts'} />
              <div className="alert-item"></div> 
            </div>
          </div>
    </div>
  );
};

export default Alerts;