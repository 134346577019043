import React, { useState } from 'react';

import { SettingsSVG } from '../../images/SVGElements/Settings';

import './Header.css';



function Header({ type }) {

    const [activeTab, setActiveTab] = useState('wallet');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    if (type === 'desktop') {
        return (
            <header className="top">
                <div className="search-top-panel">
                    <input type="text" className="rectangle" placeholder="Search pair by symbol, name, contract or token" />
                </div>
                <div className="frame-wrapper">
                    <div className="frame-2">
                        <button className="btn">
                            <div className="text-wrapper-2">Connect</div>
                        </button>
                        <SettingsSVG className={'setting-svg'} />
                    </div>
                </div>
            </header>
        );
    }

    if (type === 'wishlist') {
        return (
            <header className="top">
                <div className="top-switcher">
                    <button
                        className={activeTab === 'wallet' ? 'active' : ''}
                        onClick={() => handleTabChange('wallet')}
                    >
                        Wallets Watchlist
                    </button>
                    <button
                        className={activeTab === 'token' ? 'active' : ''}
                        onClick={() => handleTabChange('token')}
                    >
                        Token Watchlist
                    </button>
                </div>
                <div className="frame-wrapper">
                    <div className="frame-2">
                        <button className="btn">
                            <div className="text-wrapper-2">Connect</div>
                        </button>
                        <SettingsSVG className={'setting-svg'} />
                    </div>
                </div>
            </header>
        );
    }

    if (type === 'newPairsToken') {
        return (
            <header className="top">
                <div className="search-top-panel">
                    <input type="text" className="rectangle" placeholder="Search pair by symbol, name, contract or token" />
                </div>
                <div className="frame-wrapper">
                    <div className="frame-2">
                        <button className="btn">
                            <div className="text-wrapper-2">Connect</div>
                        </button>
                        <SettingsSVG className={'setting-svg'} />
                    </div>
                </div>
            </header>
        );
    }


}

export default Header;
