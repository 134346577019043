import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";

import "./NewPairsToken.css";
import LivePairsTokenTable from "../../components/LivePairsTokenTable/LivePairsTokenTable";
import BlockchainFilter from "../../components/BlockchainFilter/BlockchainFilter";
// import BlockchainFilter from "../../components/BlockchainFilter/BlockchainFilter";

function NewPairsToken () {

const [data, setData] = useState([]);

useEffect(() => {
    fetch('http://localhost:8888/api/contracts')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  return (
    <div className="desktop">
          <div className="right-screen">
            <div className="right-screen_container">
                <Header type={'newPairsToken'} />
                <BlockchainFilter />
                <LivePairsTokenTable data={data} />
            </div>
          </div>
    </div>
  );
};

export default NewPairsToken;