import React from 'react';
// import { ethers } from 'ethers';
// import TokenAbi from 'erc-20-abi';
import { FavoriteSVG } from '../../images/SVGElements/Favorite';

const LivePairsTokenTableField = ({ data, openLink }) => {

const formatISODate = (isoDate) => {
  const date = new Date(Number(isoDate));
  const options = { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
  return date.toLocaleTimeString('en-US', options);
}

const formatETHAddress = (address) => {
  const prefix = address.slice(0, 8);
  const suffix = address.slice(-4);

  return `${prefix}...${suffix}`;
};

  return (
    <tr>
      <td className='td-left'>
        {/* <span className='token-index'>#{data.key}</span> {token0Symbol} / {token1Symbol} */}
          {formatISODate(data._id)}
      </td>
      <td className='td-center' style={{cursor: 'pointer'}} onClick={() => openLink(data.address)}>
        {formatETHAddress(data.address)}
      </td>
      <td className='td-center'>{data.chain}</td>
      <td className='td-right'>
        <FavoriteSVG className="favorite-icon" />
      </td>
    </tr>
  );
};

export default LivePairsTokenTableField;