import React from 'react';
// import { ethers } from 'ethers';
// import TokenAbi from 'erc-20-abi';
import { FavoriteSVG } from '../../images/SVGElements/Favorite';

const LivePairsWishlistTableField = ({ data }) => {

  return (
    <tr>
      <td className='td-left'>
        {/* <span className='token-index'>#{data.key}</span> {token0Symbol} / {token1Symbol} */}
         <span className='token-index'>#{data}</span> ETH / USDT
      </td>
      <td className='td-right' style={{cursor: 'pointer'}}>
        Uniswap V2
        </td>
      {/* <td className='td-right'>100/100</td> */}
      <td className='td-right'>$0.000000000</td>
      <td className='td-right'>1 month</td>
      <td className='td-right'>$000.00K</td>
      <td className='td-right'>$000.0K</td>
      <td className='td-right'>
        <FavoriteSVG className="favorite-icon active" />
      </td>
    </tr>
  );
};

export default LivePairsWishlistTableField;