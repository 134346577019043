import React, { useEffect } from 'react';
// import axios from 'axios';


import './LivePairsTokenTable.css';
import LivePairsTokenTableField from '../LivePairsTokenTableField/LivePairsTokenTableField';



const LivePairsTokenTable  = ({data}) => {


    const openLink = (address) => {
        window.open(`https://etherscan.io/address/${address}`, '_blank')
    }


    useEffect(() => {
        const syncScroll = () => {
            const tbody = document.querySelector('.scrollable-table tbody');
            const thead = document.querySelector('.scrollable-table thead');
            thead.style.transform = `translateY(${tbody.scrollTop}px)`;
        };

        const tbody = document.querySelector('.scrollable-table tbody');
        tbody.addEventListener('scroll', syncScroll);

        return () => {
            tbody.removeEventListener('scroll', syncScroll);
        };
    }, []);

    return (
        <>
            <div className="headers">
                <div className="frame-5">
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Rank by:</div>
                    </div>
                    <div className="frame-6">
                        <div className="text-wrapper-5">Newest</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">Gainers</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">Most volume</div>
                    </div>
                </div>
                <div className="frame-5">
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Liquidity:</div>
                    </div>
                    <div className="frame-6">
                        <div className="text-wrapper-5">Any</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$1K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$5K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$10K</div>
                    </div>
                    <div className="frame-7">
                        <div className="text-wrapper-5">&gt;$50K</div>
                    </div>
                </div>
            </div>
            <div className="table">
                <div className="scrollable-table-container">
                    <table className="scrollable-table">
                        <thead>
                            <tr>
                                <th className='th-left'>Time</th>
                                <th className='th-center'>Address</th>
                                <th className='th-center'>Chain</th>
                                <th className='th-right'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                .slice(0, 19)
                                .map((item, index) => (
                                    <LivePairsTokenTableField key={index} data={item} openLink={openLink} />
                                ))}
                                {/* {Array.from({ length: 20 }).map((_, index) => (
                                    <LivePairsTokenTableField key={index} data={index} />
                                ))} */}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
};

export default LivePairsTokenTable;
