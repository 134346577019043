import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BlockchainFilter.css';

function BlockchainFilter() {
  const location = useLocation();
  const [activeChain, setActiveChain] = useState('all-chains');

  // Функція для визначення активного ланка на основі URL
  const determineActiveChain = () => {
    const pathname = location.pathname;
    if (pathname === '/eth/pairs') {
      setActiveChain('eth-frame');
    } else {
      setActiveChain('all-chains');
    }
  };

  useEffect(() => {
    determineActiveChain();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleChainClick = (chain) => {
    setActiveChain(chain);
  };

  return (
    <div className="blockchain-filter">
      <Link
        to="/pairs"
        className={`all-chains ${activeChain === 'all-chains' ? 'active' : ''}`}
        onClick={() => handleChainClick('all-chains')}
      >
        <div className="text-wrapper-3">All chains</div>
      </Link>
      {/* <Link
        to="/eth/pairs"
        className={`frame-3 eth-frame ${activeChain === 'bsc-frame' ? 'active' : ''}`}
        onClick={() => handleChainClick('bsc-frame')}
      >
        <div className="ellipse" />
        <div className="text-wrapper-4">BSC</div>
      </Link> */}
      <Link
        to="/eth/pairs"
        className={`frame-3 eth-frame ${activeChain === 'eth-frame' ? 'active' : ''}`}
        onClick={() => handleChainClick('eth-frame')}
      >
        <div className="ellipse-2" />
        <div className="text-wrapper-4 eth">Ethereum</div>
      </Link>
      {/* <Link
        to="/arb/pairs"
        className={`frame-3 eth-frame ${activeChain === 'arb-frame' ? 'active' : ''}`}
        onClick={() => handleChainClick('arb-frame')}
      >
        <div className="ellipse-3" />
        <div className="text-wrapper-4">Arbitrum</div>
      </Link>
      <Link
        to="/pol/pairs"
        className={`frame-3 eth-frame ${activeChain === 'pol-frame' ? 'active' : ''}`}
        onClick={() => handleChainClick('pol-frame')}
      >
        <div className="ellipse-4" />
        <div className="text-wrapper-4">Polygon</div>
      </Link>
      <Link
        to="/opt/pairs"
        className={`frame-3 eth-frame ${activeChain === 'opt-frame' ? 'active' : ''}`}
        onClick={() => handleChainClick('opt-frame')}
      >
        <div className="ellipse-5" />
        <div className="text-wrapper-4">Optimism</div>
      </Link> */}
      {/* <div className="frame-3">
        <div className="ellipse-6" />
        <div className="text-wrapper-4">Solana</div>
      </div>
      <div className="frame-3">
        <div className="ellipse-7" />
        <div className="text-wrapper-4">Avalanche</div>
      </div> */}
    </div>
  );
}

export default BlockchainFilter;
