import React from "react";
import Header from "../../components/Header/Header";

import "./Wishlist.css";
import LivePairsWishlistTable from "../../components/LivePairsWishlistTable/LivePairsWishlistTable";
// import BlockchainFilter from "../../components/BlockchainFilter/BlockchainFilter";


function Wishlist () {
  return (
    <div className="desktop">
          <div className="right-screen">
            <div className="right-screen_container">
                <Header type={'wishlist'} />
                {/* <BlockchainFilter setChain={setChain} /> */}
                <LivePairsWishlistTable />
            </div>
          </div>
    </div>
  );
};

export default Wishlist;