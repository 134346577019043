import React from 'react';
import { FavoriteSVG } from '../../images/SVGElements/Favorite';

function formatNumberInNormalView(scientificNumber) {
  const number = parseFloat(scientificNumber);
  const precision = 2;

  let normalizedNumber = number.toPrecision(precision);

  const abbreviations = ['', 'K', 'M', 'B', 'T', 'Q'];

  let index = 0;
  while (normalizedNumber >= 1000) {
    normalizedNumber /= 1000;
    index++;
  }

  const formattedNumber = `$${parseFloat(normalizedNumber).toFixed(2)}${abbreviations[index]}`;

  return formattedNumber;
}


function formatTimestampToRelativeTime(timestamp) {
  const currentDate = new Date();
  const providedDate = new Date(timestamp);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - providedDate;

  // Define time intervals
  const intervals = [
    { label: 'y', seconds: 31536000 }, // 1 year = 365 days
    { label: 'mo', seconds: 2592000 },  // 1 month = 30 days
    { label: 'd', seconds: 86400 },     // 1 day
    { label: 'h', seconds: 3600 },      // 1 hour
    { label: 'm', seconds: 60 },       // 1 minute
    { label: 's', seconds: 1 },        // 1 second
  ];

  // Find the appropriate interval
  for (const interval of intervals) {
    const count = Math.floor(differenceInMilliseconds / (1000 * interval.seconds));
    if (count >= 1) {
      return `${count}${interval.label}`;
    }
  }

  return 'Just now';
}


const LivePairsTableField = ({ data }) => {

  return (
    <tr>
      <td className='td-left'>
       {data.token0} / {data.token1}
         {/* <span className='token-index'>#{data}</span> ETH / USDT */}
      </td>
      <td className='td-right' style={{cursor: 'pointer'}}>
        {data.dex}
        </td>
      {/* <td className='td-right'>100/100</td> */}
      <td className='td-right'>${data?.pairInfo?.priceUsd}</td>
      <td className='td-right'>{formatTimestampToRelativeTime(data?.pairInfo?.pairCreatedAt)}</td>
      <td className='td-right'>{formatNumberInNormalView(data?.pairInfo?.liquidity?.usd)}</td>
      <td className='td-right'>{data?.pairInfo?.fdv ? data?.pairInfo?.fdv : 'N/A'}</td>
      <td className='td-right'>
        <FavoriteSVG className="favorite-icon" />
      </td>
    </tr>
  );
};

export default LivePairsTableField;