import React from "react";

import "./TokenDetails.css";
import TradingViewC from "../../components/TradingViewC/TradingViewC";


function TokenDetails () {
  return (
    <div className="desktop">
          <div className="right-screen">
            <TradingViewC />
          </div>
    </div>
  );
};

export default TokenDetails;