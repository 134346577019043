import React, { useState, useEffect } from 'react';
import { GroupSVG } from '../../images/SVGElements/LiveToken';
import { PairSVG } from '../../images/SVGElements/TokenDetails';
import { WishlistSVG } from '../../images/SVGElements/Wishlist';
import { AlertSVG } from '../../images/SVGElements/Alerts';
import { BotSVG } from '../../images/SVGElements/NewPairsBot';
import LogoBig from '../../images/logo_big.png';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(''); // Стан для збереження активного URL
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (location.pathname === '/pairs') {
      setActiveItem('pairs');
    } else if (location.pathname === '/token-details') {
      setActiveItem('token-details');
    } else if (location.pathname === '/wishlist') {
      setActiveItem('wishlist');
    } else if (location.pathname === '/alerts') {
      setActiveItem('alerts');
    } else if (location.pathname === '/new-tokens') {
      setActiveItem('new-tokens');
    } else if (location.pathname === '/pairs#alerts') {
      setActiveItem('alerts');
    } else if (location.pathname === '/token-details#alerts') {
      setActiveItem('alerts');
    } else if (location.pathname === '/wishlist#alerts') {
      setActiveItem('alerts');
    } else if (location.pathname === '/new-tokens#alerts') {
      setActiveItem('alerts');
    }
  }, [location.pathname]);

  return (
    <div>
      <div
        id="mySidebar"
        className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
        onMouseEnter={openSidebar}
        onMouseLeave={closeSidebar}
        style={{
          transition: 'width 0.2s ease-in',
        }}
      >
        <Link
          to="/pairs"
          className={`mySidebar-item-logo ${activeItem === 'pairs' ? 'active' : ''}`}
        >
          <span>
            <img className="logo" src={LogoBig} alt="Logo" />
            <span className={`icon-text-logo ${!isSidebarOpen ? 'hidden' : ''}`}>Token<span>Screener</span></span>
          </span>
        </Link>
        <Link
          to="/pairs"
          className={`mySidebar-item ${activeItem === 'pairs' ? 'active' : ''}`}
        >
          <span>
            <GroupSVG />
            <span className={`icon-text ${!isSidebarOpen ? 'hidden' : ''}`}>Live pools board</span>
          </span>
        </Link>
        <Link
          to="/token-details"
          className={`mySidebar-item ${activeItem === 'token-details' ? 'active' : ''}`}
        >
          <span>
            <PairSVG />
            <span className={`icon-text ${!isSidebarOpen ? 'hidden' : ''}`}>Token details</span>
          </span>
        </Link>
        <Link
          to="/wishlist"
          className={`mySidebar-item ${activeItem === 'wishlist' ? 'active' : ''}`}
        >
          <span>
            <WishlistSVG />
            <span className={`icon-text ${!isSidebarOpen ? 'hidden' : ''}`}>Wishlist</span>
          </span>
        </Link>
        <Link 
          to='/alerts'
          className={`mySidebar-item ${activeItem === 'alerts' ? 'active' : ''}`}
        >
          <span>
            <AlertSVG />
            <span className={`icon-text ${!isSidebarOpen ? 'hidden' : ''}`}>Alerts</span>
          </span>
        </Link>
        <Link
          to="/new-tokens"
          className={`mySidebar-item ${activeItem === 'new-tokens' ? 'active' : ''}`}
        >
          <span>
            <BotSVG />
            <span className={`icon-text ${!isSidebarOpen ? 'hidden' : ''}`}>New tokens</span>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
